// ul#listSkillsImg{
//     list-style: none;
// }
// ul#listSkillsImg li{
//     width: 4rem;
//     display: inline-block;
//     margin: 4rem;
// }
// ul#listSkillsImg li img{
//     width: 4rem;
//     height: 4rem;
// }

.skillsContainer{
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
}

.skillsContainer .skill {
    margin: 10px;
    border-radius: 10%;
    // padding: 30px;
    // border: 2px solid #e0a800 ;
    background-color: #6c80a7;
    box-shadow: 2px 2px 2px 2px #e0a800;
    width: 8em;
    height: 8em;
    h6{
        color: #e0a800;
        font-size: large;
        font-weight: bold;
    }
}

// .skillsContainer .skill img {
//     filter: blur(1px);
// }